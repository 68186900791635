<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <a @click="goBack" class="margin-bottom" style="border: 1px black"><span class="fa fa-long-arrow-left"></span>
          Back</a>
        <br/>
        <br/>
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Edit Banner Zone</strong>
            </div>
            <div class="text-muted">Edit Banner Zone information</div>
          </div>
          <div class="card-body">
            <a-form v-if="bannerzoneData" :form="form" @submit.prevent="handleSubmit">
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Banner Zone's name.</span>
                      </template>
                      <a-input type="text" placeholder="Banner Zone Name" v-decorator="[ 'name', {
                  initialValue: bannerzoneData.Name,
                  rules: [
                    { required: true, message: 'Name is required.' },
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Size">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Banner Zone's placement size in pixels.</span>
                      </template>
                      <a-select
                        v-decorator="['bannerSize', { initialValue: bannerSize, rules: [{ required: false, message: 'Please select a size' }]}]"
                        placeholder="Banner Size"
                        :showSearch="true"
                        :filterOption="true"
                        optionFilterProp="children"
                        @change="isCustomSizeCheck"
                      >
                        <a-select-option :key="index" v-for="(size, index) in bannerSizes">{{
                            size.label
                          }}
                        </a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item v-if="isCustomSize" :label-col="labelCol" :wrapper-col="wrapperCol" label="Width">
                    <a-input-number
                      :min="0"
                      :max="9999"
                      style="width: 100%"
                      placeholder="Width"
                      v-decorator="['Width',
                    { initialValue: bannerzoneData.Width, type: 'number', rules: [
                    { required: isCustomSize, message: 'Width is required.' },] }]"/>
                  </a-form-item>

                  <a-form-item v-if="isCustomSize" :label-col="labelCol" :wrapper-col="wrapperCol" label="Height">
                    <a-input-number
                      :min="0"
                      :max="9999"
                      style="width: 100%"
                      placeholder="Height"
                      v-decorator="['Height',
                    { initialValue: bannerzoneData.Height, rules: [
                    { required: isCustomSize, message: 'Height is required.' },] }]"/>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bidfloor">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Minimum bid expressed in CPM. Value will be used as a bidfloor in RTB auctions.</span>
                      </template>
                      <a-input type="float" min="0" max="100" step="0.0001" placeholder="Bidfloor"
                                v-decorator="[ 'bidfloor', { initialValue: bannerzoneData.Bidfloor, rules: [{validator: cpmValidator}] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar"/>
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Publisher Revenue Share">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Percentage of a revenue (bid price minus bid floor) from Remote RTB Zone's advertiser that Banner Zone's publisher earns.</span>
                      </template>
                      <a-input type="number" min="0" max="100" suffix="%" v-decorator="['publisherRevShare', {
                        initialValue: bannerzoneData.PublisherRevShare,}]" placeholder="Publisher Revenue Share">
                      </a-input>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Domains/Brands Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of domains or brand names to block.</span>
                      </template>
                      <div class="list-flex">
                      <a-select
                        class="select-width"
                        v-decorator="['AdDomainsBrandsBlacklist', { initialValue: bannerzoneData.AdDomainsBrandsBlacklist === '' ? [] : bannerzoneData.AdDomainsBrandsBlacklist.split('\n') }]"
                        @change="handleAdDomains"
                        placeholder="Ad Domain/Brands Blacklist"
                        :showSearch="true"
                        mode="tags"
                        :tokenSeparators="[' ']"
                        :filterOption="true"
                        optionFilterProp="children"
                      >
                      </a-select>
                      <a-button
                        class="btn-info copy-button-width"
                        @click="copyList('brands')"
                      ><i class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Ad Apps Blacklist">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Blacklist of applications by their application identifiers. On Android, these should be bundle or package names (e.g., com.foo.mygame), on iOS, these are numeric IDs.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['AdAppsBlacklist', { initialValue: bannerzoneData.AdAppsBlacklist === '' ? [] : bannerzoneData.AdAppsBlacklist.split('\n') }]"
                          @change="handleAppsBlacklist"
                          placeholder="Ad Apps Blacklist"
                          :filterOption="true"
                          optionFilterProp="children"
                          mode="tags"
                          :tokenSeparators="[' ']"
                        >
                        </a-select>
                        <a-button
                          class="btn-info mr-1 copy-button-width"
                          @click="copyList('apps')"
                        ><i class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Keywords">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>List of keywords describing the site/app.</span>
                      </template>
                      <div class="list-flex">
                        <a-select
                          v-decorator="['Keywords', { initialValue: bannerzoneData.Keywords === '' ? [] : bannerzoneData.Keywords.split(',') }]"
                          @change="handleKeywords"
                          placeholder="Keywords"
                          :showSearch="true"
                          :filterOption="true"
                          optionFilterProp="children"
                          mode="tags"
                          :tokenSeparators="[' ']"
                        >
                        </a-select>
                        <a-button
                          class="btn-info mr-1 copy-button-width"
                          @click="copyList('keywords')"
                        ><i class="fa fa-copy"></i></a-button>
                      </div>
                    </a-tooltip>
                  </a-form-item>

                </div>
                <div class="w-100">

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Site Domain">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Domain of the site.</span>
                      </template>
                      <a-input type="text"
                               v-decorator="['SiteDomain', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.'} ], initialValue: bannerzoneData.SiteDomain}]"
                               placeholder="Site Domain"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Name">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>App name.</span>
                      </template>
                      <a-input type="text"
                               v-decorator="['AppName', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.'} ], initialValue: bannerzoneData.AppName}]"
                               placeholder="App Name"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Bundle">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>A platform-specific application identifier intended to be unique to the app and independent of the exchange. On Android, these should be bundle or package names (e.g., com.foo.mygame), on iOS, these are numeric IDs.</span>
                      </template>
                      <a-input type="text"
                               v-decorator="['AppBundle', { rules: [{ max: 64, message: 'Maximum 64 characters allowed.'} ], initialValue: bannerzoneData.AppBundle}]"
                               placeholder="App Bundle"/>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="App Store URL">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>App Store URL for an installed app.</span>
                      </template>
                      <a-input type="url"
                               v-decorator="['AppStoreUrl', { rules: [{ max: 256, message: 'Maximum 256 characters allowed.'} ], initialValue: bannerzoneData.AppStoreUrl}]"
                               placeholder="App Store URL"/>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Tmax">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum time in milliseconds to wait for the response from Remote RTB Zone's server.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Tmax"
                              v-decorator="[ 'tmax', { initialValue: bannerzoneData.Tmax }]"/>
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Auction Type">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Type of RTB auction within the platform.</span>
                      </template>
                      <a-select
                        v-decorator="['auctionType', { initialValue: bannerzoneData.AuctionType === 1 ? '1' : '2' }]"
                        placeholder="Auction Type"
                        :filterOption="true"
                        optionFilterProp="children"
                      >
                        <a-select-option value='1'>First Price</a-select-option>
                        <a-select-option value='2'>Second Price Plus</a-select-option>
                      </a-select>
                    </a-tooltip>
                  </a-form-item>

                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Secure">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Indicates if the Banner Zone requires secure HTTPS URL creative assets and markup.</span>
                      </template>
                      <a-switch v-if="secureValue !== undefined" checked-children="Enabled"
                                un-checked-children="Disabled"
                                v-model="secureValue"/>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Ad Position</a-divider>
              <Bannerposition v-on:setPosition="setAdPosition($event)" :positionKey="adPosition" />
              <a-divider style="margin-top: 34px;">Limits</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Requests Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of requests to receive per day. The system will even out distribution of requests throughout the day, ie. "day shaping". Set to 0 for unlimited requests.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Requests Daily Limit"
                        v-decorator="['requestsDailyLimit', { initialValue: bannerzoneData.RequestsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Bids Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of bids per day. The system will even out distribution of bids throughout the day, ie. "day shaping". Set to 0 for unlimited bids.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Bids Daily Limit"
                        v-decorator="['bidsDailyLimit', { initialValue: bannerzoneData.BidsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>

                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Impressions Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Number of impressions per day. The system will even out distribution of impressions throughout the day, ie. "day shaping". Set to 0 for unlimited impressions.</span>
                      </template>
                      <a-input type="number" min="0" placeholder="Impressions Daily Limit"
                        v-decorator="['impressionsDailyLimit', { initialValue: bannerzoneData.ImpressionsDailyLimit, rules: [{ validator: cpmValidator }] }]" />
                    </a-tooltip>
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Spent Daily Limit">
                    <a-tooltip placement="top">
                      <template slot="title">
                        <span>Maximum daily amount (USD) to spend on Banner Zone. Set to 0 for unlimited amount.</span>
                      </template>
                      <a-input type="number" min="0" step="0.0001" placeholder="Spent Daily Limit"
                        v-decorator="['spentDailyLimit', { initialValue: bannerzoneData.SpentDailyLimit, rules: [{ validator: cpmValidator }] }]">
                        <a-tooltip slot="addonBefore" trigger="click">
                          <a-icon type="dollar" />
                        </a-tooltip>
                      </a-input>
                    </a-tooltip>
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Categories & Ad Attributes</a-divider>
              <div class="flex">
                <div class="w-100 -m">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="IAB Categories">
                    <a-tree
                      checkable
                      v-model="checkedComputedKeys"
                      :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedKeys"
                      :tree-data="treeData"
                      @expand="onExpand"
                      @select="onSelect"
                      optionFilterProp="children"
                    />
                  </a-form-item>
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Blocked Ad IAB Categories">
                    <a-tree
                      checkable
                      v-model="checkedBlockedComputedKeys"
                      :auto-expand-parent="autoExpandParent"
                      :selected-keys="selectedBlockedKeys"
                      :tree-data="treeData"
                      @expand="onExpand"
                      @select="onBlockSelect"
                      optionFilterProp="children"
                    />
                  </a-form-item>
                </div>
                <div class="w-100">
                  <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Blocked Ad Attributes">
                    <a-tree
                      checkable
                      class="break-line"
                      v-model="checkedBlockedComputedAdKeys"
                      :selected-keys="selectedAdKeys"
                      :tree-data="adTreeData"
                      @expand="onExpand"
                      @select="onBlockedAdSelect"
                      optionFilterProp="children"
                    />
                  </a-form-item>
                </div>
              </div>
              <a-divider style="margin-top: 34px;">Demand</a-divider>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Campaigns">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Array of Campaigns' IDs to connect to the Banner Zone. This will make the Campaign Ad appear for Banner Zone if all the targeting is matched.</span>
                    </template>
                    <a-select
                      v-decorator="['campaigns', { initialValue: (bannerzoneData.Campaigns === '' ? [] : bannerzoneData.Campaigns) }]"
                      placeholder="Please select Campaigns" :showSearch="true" :filterOption="true" mode="multiple"
                      optionFilterProp="children">
                      <a-select-option class="zones" v-for="obj in activeCampaigns" :key="obj.Id">[{{  obj.Id  }}] {{  obj.Name  }}
                      </a-select-option>
                    </a-select>
                  </a-tooltip>
                </a-form-item>
                <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Remote RTB Zones">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Remote RTB Zones to connect to the Banner Zone. This will make the Remote RTB Zone appear for Banner Zone if all the targeting is matched.</span>
                    </template>
                    <a-select
                      v-decorator="['remotertbzones', { initialValue: (bannerzoneData.Remotertbzones === '' ? [] : bannerzoneData.Remotertbzones) }]"
                      placeholder="Please select Remote RTB Zones" :showSearch="true" :filterOption="true" mode="multiple"
                      optionFilterProp="children">
                      <a-select-option class="zones" v-for="obj in activeRemotertbzones" :key="obj.Id">[{{  obj.Id  }}] {{  obj.Name  }}
                      </a-select-option>
                    </a-select>
                  </a-tooltip>
                </a-form-item>
              <a-divider class="mt-3"></a-divider>
              <div class="ant-row ant-form-item">
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Save Changes</button>
                  <button type="button"
                          :class="'btn btn-'+(bannerzoneData.Status === 0 ? 'danger' : 'primary')+' px-5 ml-2'"
                          @click.prevent="toggleBannerZoneStatus">
                    {{ bannerzoneData.Status == 0 ? 'Deactivate' : 'Activate' }} Banner Zone
                  </button>
                  <button type="button" @click="$router.push({ name: 'bannerzones' })"
                          class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
            <a-spin v-if="bannerzoneData === null"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { Modal } from 'ant-design-vue'
import { getIabCategories } from '@/api/info'
import { getBannerZones } from '@/api/display/bannerzone.js'
import bannerSizes from '@/views/display/data/zoneSize.json'
import adTreeData from '@/views/display/data/blockedAds.json'
import Vue from 'vue'

export default {
  components: {
    Bannerposition: () => import('@/components/custom/banner-position/index.vue'),
  },
  watch: {
    bannerzoneData(val) {
      if (val !== undefined) {
        this.checkedKeys = val.IabCategories
        this.checkedBlockedKeys = val.BlockedAdIabCategories
        this.checkedBlockedComputedAdKeys = val.BlockedAdAttributes
        this.isCustomSize = this.bannerSize === (this.bannerSizes.length - 2)
        this.secureValue = val.Secure === 1
        this.adPosition = val.AdPosition
      }
    },
  },
  computed: {
    ...mapState(['ORTBpublisher', 'ortbCampaign', 'bannerad', 'remotertbzone']),
    ...mapGetters('bannerad', ['getBannerAds', 'getActiveBannerAds']),
    ...mapGetters('ortbCampaign', ['getCampaigns', 'getActiveORTBCampaigns']),
    ...mapGetters('remotertbzone', ['getRemoteRTBZones', 'getActiveRemoteRTBZones']),
    bannerSize: function () {
      const index = this.bannerSizes.findIndex((el) => {
        return el.size.width === this.bannerzoneData.Width && el.size.height === this.bannerzoneData.Height
      })
      return index === -1 ? this.bannerSizes.length - 2 : index
    },
    activeCampaigns: function () {
      return this.getActiveORTBCampaigns
    },
    activeRemotertbzones: function () {
      return this.getActiveRemoteRTBZones
    },
    checkedComputedKeys: {
      // getter
      get: function () {
        return this.treeData.length === 0 ? [] : this.checkedKeys
      },
      // setter
      set: function (newValue) {
        this.checkedKeys = newValue
      },
    },
    checkedBlockedComputedKeys: {
      // getter
      get: function () {
        return this.treeData.length === 0 ? [] : this.checkedBlockedKeys
      },
      // setter
      set: function (newValue) {
        this.checkedBlockedKeys = newValue
      },
    },
    checkedBlockedComputedAdKeys: {
      // getter
      get: function () {
        return this.adTreeData.length === 0 ? [] : this.checkedBlockedAds
      },
      // setter
      set: function (newValue) {
        this.checkedBlockedAds = newValue
      },
    },
    selectedBlockedComputedAdKeys: {
      // getter
      get: function () {
        return this.adTreeData.length === 0 ? [] : this.selectedAdKeys
      },
      // setter
      set: function (newValue) {
        this.selectedAdKeys = newValue
      },
    },
    // computedHeight: {
    //   // getter
    //   get: function () {
    //     return this.bannerData.height === '' ? '' : this.computedHeight
    //   },
    //   // setter
    //   set: function (newValue) {
    //     this.computedHeight = newValue
    //   },
    // },
    // computedWidth: {
    //   // getter
    //   get: function () {
    //     return this.bannerData.width === '' ? '' : this.computedWidth
    //   },
    //   // setter
    //   set: function (newValue) {
    //     this.computedWidth = newValue
    //   },
    // },
    isCustomSize: {
      // getter
      get: function () {
        return this.isCustomSizeVal
      },
      // setter
      set: function (newValue) {
        this.isCustomSizeVal = newValue
      },
    },
  },
  data() {
    return {
      bannerzoneData: null,
      secureValue: undefined,
      bannerSizes: bannerSizes,
      isCustomSizeVal: false,
      unformattedResponse: [],
      treeData: [],
      adTreeData: adTreeData,
      IabCategories: [],
      keywordLists: [],
      adPosition: null,
      keywordListsType: [
        {
          Id: 0,
          Name: 'Exact',
        },
        {
          Id: 1,
          Name: 'Phrase',
        },
        {
          Id: 2,
          Name: 'Broad',
        },
      ],
      autoExpandParent: false,
      showCustomInput: false,
      checkedBlockedAds: [],
      selectedAdKeys: [],
      checkedKeys: [],
      selectedKeys: [],
      checkedBlockedKeys: [],
      selectedBlockedKeys: [],
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    setAdPosition(key) {
      this.adPosition = key
    },
    cpmValidator(rule, value, message) {
      if (value === '') {
        message()
      } else if (isNaN(parseFloat(value))) {
        message('Invalid number.')
      } else if (parseFloat(value) < 0) {
        message('Value can not be negative.')
      }
      message()
    },
    copyList(type) {
      if (type === 'apps') {
        navigator.clipboard.writeText(this.bannerzoneData.AdAppsBlacklist)
        Vue.prototype.$notification.success({
          message: 'Ad Apps Blacklist copied to clipboard.',
        })
      } else if (type === 'brands') {
        navigator.clipboard.writeText(this.bannerzoneData.AdDomainsBrandsBlacklist)
        Vue.prototype.$notification.success({
          message: 'Ad Domains/Brands Blacklist copied to clipboard.',
        })
      } else if (type === 'keywords') {
        navigator.clipboard.writeText(this.bannerzoneData.Keywords.replaceAll(',', '\n'))
        Vue.prototype.$notification.success({
          message: 'Keywords copied to clipboard.',
        })
      }
    },
    isCustomSizeCheck(val) {
      this.isCustomSize = val === (this.bannerSizes.length - 2)
    },
    toggleCustomInput() {
      this.showCustomInput = true
    },
    newLineSplit(string) {
      return string.split('\n')
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys
    },
    onSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys
    },
    onBlockedAdCheck(checkedBlockedAds) {
      this.checkedBlockedAds = checkedBlockedAds
    },
    onBlockedAdSelect(selectedAdKeys, info) {
      this.selectedAdKeys = selectedAdKeys
    },
    onBlockCheck(checkedBlockedKeys) {
      this.checkedBlockedKeys = checkedBlockedKeys
    },
    onBlockSelect(selectedBlockedKeys, info) {
      this.selectedBlockedKeys = selectedBlockedKeys
    },
    toggleBannerZoneStatus() {
      const status = this.bannerzoneData.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Banner Zone status?',
        content: h => <div>{status} Banner Zone <strong>{this.bannerzoneData.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = this.bannerzoneData.Status === 0 ? 1 : 0
          this.$store.dispatch('bannerzone/CHANGE_STATUS', {
            id: this.bannerzoneData.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    handleAdDomains(data) {
      this.bannerzoneData.AdDomainsBrandsBlacklist = data.join('\n')
    },
    handleAppsBlacklist(data) {
      this.bannerzoneData.AdAppsBlacklist = data.join('\n')
    },
    handleKeywords(data) {
      this.bannerzoneData.Keywords = data.join(',')
    },
    goBack() {
      this.$router.go(-1)
    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.isCustomSize) {
            values.Width = this.bannerSizes[values.bannerSize].size.width
            values.Height = this.bannerSizes[values.bannerSize].size.height
          }
          const parentSelectedIab = this.checkedKeys.filter(checkedKey => this.unformattedResponse.includes(checkedKey))
          const array = this.treeData.map(element => parentSelectedIab.includes(element.key) ? element.children : []).flat().map(el => el.key)
          const newArr = this.checkedKeys.filter(el => !array.includes(el))

          const parentSelectedBlockedIab = this.checkedBlockedKeys.filter(checkedBlockedKey => this.unformattedResponse.includes(checkedBlockedKey))
          const blockedIabArray = this.treeData.map(blockedElement => parentSelectedBlockedIab.includes(blockedElement.key) ? blockedElement.children : []).flat().map(el => el.key)
          const newBlockedArray = this.checkedBlockedKeys.filter(blockedEl => !blockedIabArray.includes(blockedEl))

          values.AdPosition = this.adPosition
          values.Secure = this.secureValue ? 1 : 0
          values.IabCategories = newArr
          values.BlockedAdIabCategories = newBlockedArray
          values.BlockedAdAttributes = this.checkedBlockedComputedAdKeys
          values.AdDomainsBrandsBlacklist = this.bannerzoneData.AdDomainsBrandsBlacklist
          values.AdAppsBlacklist = this.bannerzoneData.AdAppsBlacklist
          values.Keywords = this.bannerzoneData.Keywords
          if (values.AppStoreUrl !== '' && values.AppStoreUrl) {
            values.AppStoreUrl = values.AppStoreUrl.replace(/ /g, '')
          }
          if (values.SiteDomain !== '' && values.SiteDomain) {
            values.SiteDomain = values.SiteDomain.replace(/ /g, '')
          }
          this.$store.dispatch('bannerzone/UPDATE_BANNERZONE', {
            id: this.bannerzoneData.Id,
            payload: values,
            callback: function () {
              this.$router.push({ name: 'bannerzones' })
            }.bind(this),
          })
        }
      })
    },
    placeholder(key) {
      return (typeof this.bannerzoneData[key] === 'undefined') ? '' : this.bannerzoneData[key]
    },

  },
  created() {
    getIabCategories().then(response => {
      const formattedResponse = []
      response.forEach((el, id) => {
        const temp = {
          title: '(' + el.Name + ')' + ' ' + el.Title,
          key: el.Name,
          children: [],
        }
        el.Subcategories.forEach((element) => {
          temp.children.push({
            title: '(' + element.Name + ')' + ' ' + element.Title,
            key: element.Name,
          })
        })
        formattedResponse.push(temp)
        this.treeData = formattedResponse
      })
      response.forEach(element => {
        this.unformattedResponse.push(element.Name)
      })
    })
    getBannerZones(this.$route.params.id).then((response) => {
      this.bannerzoneData = response
      this.checkedKeys = this.bannerzoneData.IabCategories
      this.checkedBlockedKeys = this.bannerzoneData.BlockedAdIabCategories
      this.checkedBlockedComputedAdKeys = this.bannerzoneData.BlockedAdAttributes
      this.isCustomSize = this.bannerSize === (this.bannerSizes.length - 2)
      this.secureValue = this.bannerzoneData.Secure === 1
      if (this.bannerzoneData.EventTrackers !== {}) {
        this.eventTrackers = this.bannerzoneData.EventTrackers
      }
    })
    this.$store.dispatch('ortbCampaign/LOAD_CAMPAIGNS_SHORT')
    // this.$store.dispatch('kwlist/LOAD_KWLISTS')
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
.list-flex {
  display: flex;
  align-content: flex-start;
}
.select-width {
  width: 90%;
}
.copy-button-width {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem !important;
  height: 2.1rem !important;
}

@media only screen and (min-width: 768px) {
  .flex {
    display: flex;
    flex-direction: col;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .-m {
    margin-right: -4rem;
  }

  .break-line >>> li .ant-tree-node-content-wrapper {
    margin-bottom: 5px;
    padding-top: 4px;
    display: inline-block !important;
    white-space: pre-wrap !important;
    line-height: 15px !important;
    width: 90% !important;
  }
}
</style>
